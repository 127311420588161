<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
<!--    <a-form-model ref="form" :model="form" :rules="rules">-->
    <a-form-model ref="form" :model="form" :rules="rules">

      <a-form-model-item label="平台用户" prop="userId" >
        <!--        <a-input v-model="form.videoId" placeholder="请输入短剧id" />-->
        <a-select
          v-model="form.userId"
          showSearch
          style="width: 100%"
          :disabled="this.types==2"
          placeholder="请选择平台用户"
          allow-clear
          optionFilterProp="children"
        >
          <a-select-option :key="item.id" v-for="(item,index) in listAll">
            {{ item.nickname }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <div><font style="color: red">初始密码为：112233Aa! 可在系统管理->参数管理中搜索defaultPwd修改初始密码</font></div>
      <a-form-model-item label="佣金比例%" prop="distributorRait" >
        <a-input-number :min="0" :precision="0" :max="100" v-model="form.distributorRait" /> (请输入0-100之间的数字)
      </a-form-model-item>



      <a-form-model-item label="赠送钻石数" prop="giveDiamond" >
        <a-input-number :min="0" :max="this.maxNum"   :precision="0" v-model="form.giveDiamond" />
      </a-form-model-item>
      <div><font style="color: red">最大数量 {{this.maxNum}}</font></div>

      <a-form-model-item label="邮箱" prop="userEmail" >
        <a-input v-model="form.userEmail" placeholder="请输入邮箱" />
      </a-form-model-item>

      <a-form-model-item label="手机号" prop="userPhone" >
        <a-input v-model="form.userPhone" placeholder="请输入手机号" />
      </a-form-model-item>

      <a-form-model-item label="银行" prop="bankName" >
        <a-select
          v-model="form.bankName"
          showSearch
          style="width: 100%"
          placeholder="请输入银行名称"
          :defaultActiveFirstOption="false"
          :showArrow="false"
          :filterOption="false"
          :notFoundContent="null"
          @search="handleSearch"
          @change="getChildBankList"
        >
          <a-select-option :key="item" v-for="(item,index) in bankList">
            {{ item }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="开户行" prop="bankAddress" >
        <a-select
          v-model="form.bankAddress"
          showSearch
          style="width: 100%"
          placeholder="请选择开户行"
          :defaultActiveFirstOption="false"
          :showArrow="false"
          :filterOption="false"
          :notFoundContent="null"
          @search="handleSearch1"
          @popupScroll="handlePopupScroll"

        >
          <a-select-option :key="item" v-for="(item,index) in childBankList">
            {{ item }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="银行卡号" prop="bankNo" >
        <a-input v-model="form.bankNo" placeholder="请输入银行卡号" />
      </a-form-model-item>

      <a-form-model-item label="真实姓名" prop="realName" >
        <a-input v-model="form.realName" placeholder="请输入真实姓名" />
      </a-form-model-item>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getDistributor, addDistributor, updateDistributor, getUserLists, getBankList, getChildBankList,getMaxNum } from '@/api/user/distributor'
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      isScroll: false,
      formTitle: '',
      types: '',
      maxNum: 0,
      listAll: [],
      bankList: [],
      bankName: null,
      name: null,
      childBankList: [],
      pageObj: {
        pageNo: 1,
        pageSize: 10,
        pages: 0, // 总页数
        total: 0, // 总条数
      },
      // 表单参数
      form: {
        id: null,

        userId: null,

        inviteUrl: null,

        inviteCode: null,

        userEmail: null,

        userPhone: null,

        distributorRait: null,

        bankName: null,

        bankNo: null,

        realName: null,
        giveDiamond: null,

        bankAddress: null,

        status: 0,

        address: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '请选择用户' }
        ],
        distributorRait: [
          { required: true, message: '请输入佣金比例', }
        ],
        userEmail: [
          { required: true, message: '请输入用户邮箱' }
          // {
          //   pattern: /^1[3/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
          //   message: '请正确填写邮箱',
          //   trigger: 'blur'
          // }
        ],
        userPhone: [
          { required: true, message: '请输入用户电话' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请正确填写联系电话',
            trigger: 'blur'
          }

        ],
        bankName: [
          { required: true, message: '请选择银行' }
        ],
        giveDiamond: [
          { required: true, message: '请输入赠送钻石数' }
        ],

        bankNo: [
          { required: true, message: '请输入银行卡号' },
          {
            pattern: /^\d{16,19}$/,
            message: '请正确填写银行卡号',
            trigger: 'blur'
          }
        ],
        realName: [
          { required: true, message: '请输入真实姓名' }
        ],
        bankAddress: [
          { required: true, message: '请选择开户地址' }
        ]
      }
    }
  },
  filters: {
  },
  created () {

  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    handlePopupScroll (e) {
      console.log(e)

      if (this.pageObj.pageNo >= this.pageObj.pages) return;

      const { target } = e;

      const { scrollTop, scrollHeight, clientHeight } = target;

      if (scrollTop + 2 + clientHeight >= scrollHeight) {
        // 已经到达底部，触发分页逻辑

        // todo 这里就可以触发加载下一页请求  具体函数根据当前业务需求来定

        this.pageObj.pageNo = ++this.pageObj.pageNo;

        this.pageObj.pageSize+1

        this.getTypeList(true)

      }

    },



    getTypeList(isScroll) {
      let param = {
        pageNo: this.pageObj.pageNo,
        bankName: this.bankName,
        name: this.name,

        pageSize: this.pageObj.pageSize

      }
      getChildBankList(param).then(res => {
        this.pageObj.pages = res.data.pages;

        this.childBankList = isScroll == false ? [] : this.childBankList;
        res.data.records.forEach((e) => {
          // 成功之后的数据应该push进数组中，而不是直接等于，否则就是下拉换页的效果了。
          this.childBankList.push(e);
        })
        console.log(111111111)
        console.log(this.childBankList)
      })
    },




    onClose () {
      this.open = false
      this.rules.userId = [
        { required: true, message: '请选择用户' }
      ]
      this.reset()
    },

    editData(record) {

      this.formType = 2
      this.types = 2
      this.reset()
      // const id = row ? row.id : ids
      this.bankName = record.bankName
      this.handleSearch(this.bankName)
      this.handleSearch1()
      getDistributor({ 'id': record.distributorId }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
      this.rules.userId[0].required = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.$nextTick(() => {
        // if(this.$refs.form){
        //   this.$refs['form'].resetFields()
        // }
      })
      this.form = {
        id: null,
        userId: null,
        inviteUrl: null,
        inviteCode: null,
        giveDiamond: null,
        userEmail: null,
        userPhone: null,
        distributorRait: null,
        bankName: null,
        bankNo: null,
        realName: null,
        bankAddress: null,
        status: 0,
        address: null,
        createTime: null,
        remark: null
      }
      this.getUserLists()

    },
    getUserLists() {
      console.log(1)
      console.log(this.formType)
      getUserLists({
        'type': this.formType
      }).then(res => {
        this.listAll = res.data
      })
    },
    handleAddUser(userId) {
      this.reset()
      this.formType = 3
      this.types = 2
      this.open = true
      this.formTitle = '添加'
      this.form.userId = userId
    },
    handleAddUsers(userId) {
      this.reset()
      this.formType = 2
      this.open = true
      this.formTitle = '添加'
      this.form.userId = userId
    },
    /** 新增按钮操作 */
    handleAdd (type) {

      this.formType = 1
      this.types=type
      this.getMaxNum()
      this.reset()
      this.$refs.form = {
        id: null,
        userId: null,
        inviteUrl: null,
        inviteCode: null,
        giveDiamond: null,
        userEmail: null,
        userPhone: null,
        distributorRait: null,
        bankName: null,
        bankNo: null,
        realName: null,
        bankAddress: null,
        status: 0,
        address: null,
        createTime: null,
        remark: null
      }
      // this.$forceUpdate()
      this.open = true
      this.formTitle = '添加'
    },
    getMaxNum() {
      getMaxNum().then(response => {
        this.maxNum = response.data
      })
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.getMaxNum()
      this.formType = 2
      this.types = 2
      this.reset()
      const id = row ? row.id : ids
      this.bankName = row.bankName
      this.handleSearch()
      this.handleSearch1(this.bankName)
      getDistributor({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
      this.rules.userId = [
        { required: false, message: '请选择用户' }
      ]

      this.getUserLists()

    },
    handleSearch(value) {
      if (value) {
        getBankList({ 'bankName': value }).then(response => {
          this.bankList = response.data
        })
      }
    },
    handleSearch1(value) {
      let params = {
        pageNo: this.pageObj.pageNo,
        bankName: this.bankName,
        name: value,

        pageSize: this.pageObj.pageSize

      }

      getChildBankList(params).then(res => {
        this.pageObj.pages = res.data.pages;
        this.childBankList = [];
        res.data.records.forEach((e) => {
          // 成功之后的数据应该push进数组中，而不是直接等于，否则就是下拉换页的效果了。

          this.childBankList.push(e);
        })

      })
      console.log(111111111)
      console.log(this.childBankList)
    },
    getChildBankList (value) {
      this.bankName = value
      this.handleSearch1()
    },

    // getBankList () {
    //   getBankList().then(response => {
    //     this.bankList = response.data
    //   })
    // },

    /** 提交按钮 */
    submitForm: function () {
      console.log(this.$refs.form)

      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            this.form.type = this.types
            updateDistributor(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            this.form.type = this.types
            addDistributor(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
